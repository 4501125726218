<template>
  <div class="flex">
    <div class="bg-white rounded-full h-1 mx-2 p-1 mt-2" />
      <slot />
  </div>
</template>

<script>
export default {

}
</script>
