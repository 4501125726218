<template>
  <div>
    <div class="bg-gray-200 w-full py-3">
      <div class="flex justify-center">
        <div class="w-3/5">
          <p>
            The Trade Tools Navigator is under continuous review. We are working
            on updating this website, and would welcome comments and suggestions
            on how best to improve it. If you have any suggestions or new sites
            and tools to add or review, please email: <br />
          </p>
          <p class="text-blue-500 text-center">trade@unep-wcmc.org</p>
        </div>
      </div>
    </div>
    <div class="py-10 flex justify-center">
      <div class="w-3/5">
        <p>
          The Trade Tools Navigator seeks to bring together existing initiatives
          in one place to help users understand the impacts of production and
          consumption on nature. The aim of the navigator is to add value to
          existing work and synthesize the tools currently available which are
          relevant to trade, impacts of trade, and commodity supply chains
        </p>
        <br />
        <p>
          Tools which are included give information on a variety of aspects
          including:
        </p>
        <br />
        <ul class="pl-4">
          <li>Overview of state of nature</li>
          <li>Impacts (direct and indirect) – especially negative impacts</li>
          <li>Drivers of impact</li>
          <li>Spatialised information</li>
          <li>Trade flows</li>
          <li>Solutions (including frameworks, best practices)</li>
        </ul>
        <br />
        <h2>Data and Methodology</h2>
        <br />
        <p>
          The data found in the Trade Tools Navigator is from existing databases
          from UNEP-WCMC from previous data inventories complied on supply
          chains, and trade. Online reviews and searches were and continue to be
          carried out for each commodity covered in the GCRF Trade Development
          and the Environment Hub using Google and key document searches, with
          standardised search terms, and targeted website searches.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
